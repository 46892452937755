import React from "react"

export default function PackagekOffer({ lang }) {
  return (
    <>
      <div className="relative max-w-7xl mx-auto pb-8 px-0 lg:px-8 " >
      </div>

      <div className="max-w-4xl mx-auto md:text-center pt-0 pb-12 px-4 sm:px-6 md:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
        <div className="block text-3xl font-bold text-green-400">
          Buy 3 sessions of 1 hour and get $150 discount
        </div>
        <div className="block text-xl font-bold">

          <br /><u>Billed at $450</u>.
          <br />
          <span className="font-normal text-lg">Standard price for 3 sessions $600.
</span>
        </div>

        <br />
        <div className="text-bold text-left">
          <b>IMPORTANT NOTE: Please check for available appointment dates in the calendar below before buying a Package.</b>
        </div>

        <div className="mt-4 flex justify-center" id="booknow">
          <div className="inline-flex">
            <a
              href="https://app.acuityscheduling.com/catalog.php?owner=30981395&action=addCart&clear=1&id=1717091"
              target="_blank" rel="noopener" rel="noreferrer"
              // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
              className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
            >
              Buy 3 sessions Now!
            </a>
          </div>
        </div>
        <br />

        <div className="text-bold text-left">
          * You will be asked to schedule your appointments after you complete your order. <br />
          * You can schedule your appointments at a later time using the link sent to your email.
          <br />
        </div>
      </div>
    </>

  )
}
